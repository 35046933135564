<template>
  <div class="borrow-info">
    <el-container class="digit">
      <el-main class="main-content">
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title">
            借入信息
          </el-header>
          <el-main>
            <el-row :gutter="16">
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="借入总金额"
                  :num="dataForm.borrowAllMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="待还总金额"
                  :num="dataForm.repaymentMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="次"
                  title="借入次数"
                  :num="dataForm.countByAllBorrow"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="次"
                  title="逾期数量"
                  :num="dataForm.countByOverdue"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="次"
                  title="未还数量"
                  :num="dataForm.countByUnBack"
                ></my-data-panel>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getBorrowInfo } from "@/api/digit";

export default {
  data() {
    return {
      dataForm: {
        borrowAllMoney: 0,
        countByAllBorrow: 0,
        countByOverdue: 0,
        countByUnBack: 0,
        repaymentMoney: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getBorrowInfo().then((res) => {
        Object.assign(this.dataForm, res.data);
      });
    },
  },
};
</script>

<style lang="less">
.borrow-info {
}
</style>
